import { INavListACL } from "@acl/acl.interfaces";
import { defaultEmployeeAcl } from "./employee.data";
import { assetAcl } from "./asset.data";
import { scheduleAcl } from "./schedule.data";
import { facilitiesSettingsAcl, generalSettingsAcl, hrmSettingsAcl, integrationSettingsAcl } from "./setting.data";
import { reportAcl } from "./report.data";

export const navListAclData: INavListACL = {
  analysis: {
    parent: { action: "analysis", resource: "analysis" },
    dashboard: { action: "dashboard", resource: "dashboard" },
    adminDashboard: {
      action: "admin-dashboard",
      resource: "admin-dashboard",
    },
    report: { action: "report", resource: "report" },
  },
  hrm: {
    parent: { action: "hrm", resource: "hrm" },
    employees: { action: "employees", resource: "employees" },
    attendances: { action: "attendances", resource: "attendances" },
    leave: { action: "leaves", resource: "leaves" },
    schedules: { action: "schedules", resource: "schedules" },
  },
  facilities: {
    parent: { action: "facilities", resource: "facilities" },
    assets: assetAcl.getAll,
  },
  generalSettings: generalSettingsAcl,
  hrmSettings: hrmSettingsAcl,
  facilitiesSettings: facilitiesSettingsAcl,
  integrationSettings: integrationSettingsAcl,

  // settings: {
  // 	accountSettings: {
  // 		action: "account-settings",
  // 		resource: "account-settings",
  // 	},
  // 	systemSettings: {
  // 		parent: settingsAcl.systemSettings.parent,
  // 		general: {
  // 			action: settingsAcl.systemSettings.general.action,
  // 			resource: settingsAcl.systemSettings.general.action,
  // 		},
  // 		address: {
  // 			action: settingsAcl.systemSettings.address.action,
  // 			resource: settingsAcl.systemSettings.address.action,
  // 		},
  // 		systemInfo: {
  // 			action: settingsAcl.systemSettings.systemInfo.action,
  // 			resource: settingsAcl.systemSettings.systemInfo.action,
  // 		},
  // 		createAccountSettings: {
  // 			action: settingsAcl.systemSettings.createAccountSettings.action,
  // 			resource:
  // 				settingsAcl.systemSettings.createAccountSettings.action,
  // 		},
  // 	},
  // 	notifications: { action: "notifications", resource: "notifications" },
  // 	hrm: {
  // 		parent: { action: "hrm-settings", resource: "hrm-settings" },
  // 		shift: { action: "shifts", resource: "shifts" },
  // 		// attendanceDevice: {
  // 		// 	action: "devices",
  // 		// 	resource: "devices",
  // 		// },
  // 		teams: { action: "teams", resource: "teams" },
  // 		groups: { action: "groups", resource: "groups" },
  // 		locations: {
  // 			action: "office-locations",
  // 			resource: "office-locations",
  // 		},
  // 	},
  // 	leave: {
  // 		parent: { action: "leave-settings", resource: "leave-settings" },
  // 		settings: {
  // 			action: "leave-settings",
  // 			resource: "leave-settings",
  // 		},
  // 		policy: {
  // 			action: "approval-policies",
  // 			resource: "approval-policies",
  // 		},
  // 		leaveType: { action: "leave-types", resource: "leave-types" },
  // 	},
  // 	employment: {
  // 		parent: { action: "employment", resource: "employment" },
  // 		type: { action: "employment-types", resource: "employment-types" },
  // 		division: { action: "divisions", resource: "divisions" },
  // 		department: { action: "departments", resource: "departments" },
  // 		grade: { action: "grades", resource: "grades" },
  // 		designation: { action: "designations", resource: "designations" },
  // 		education: { action: "educations", resource: "educations" },
  // 	},
  // 	acl: {
  // 		parent: { action: "acl", resource: "acl" },
  // 		user: { action: "users", resource: "users" },
  // 		role: { action: "roles", resource: "roles" },
  // 	},
  // 	bankInfo: {
  // 		parent: { action: "bank-info", resource: "bank-info" },
  // 		bank: { action: "banks", resource: "banks" },
  // 		branch: { action: "branches", resource: "branches" },
  // 		digitalBank: {
  // 			action: "digital-bankings",
  // 			resource: "digital-bankings",
  // 		},
  // 	},
  // 	facilities: {
  // 		parent: {
  // 			action: "setting-facilities",
  // 			resource: "setting-facilities",
  // 		},
  // 		holidays: { action: "holidays", resource: "holidays" },
  // 		assetType: { action: "asset-types", resource: "asset-types" },
  // 		holidayGroup: {
  // 			action: "holiday-groups",
  // 			resource: "holiday-groups",
  // 		},
  // 	},
  // 	integrations: {
  // 		parent: {
  // 			action: "setting-integrations",
  // 			resource: "setting-integrations",
  // 		},
  // 		attendanceLocations: {
  // 			action: "attendance-locations",
  // 			resource: "attendance-locations",
  // 		},
  // 		attendanceDevices: {
  // 			action: "devices",
  // 			resource: "devices",
  // 		},
  // 		apiSecretKeys: {
  // 			action: "api-secret-keys",
  // 			resource: "api-secret-keys",
  // 		},
  // 	},
  // },
};

export const menuListCategories: any = {
  analysis: {
    parent: navListAclData.analysis.parent,
    dashboard: {
      parent: navListAclData.analysis.adminDashboard,
      "admin-dashboard": navListAclData.analysis.adminDashboard,
    },
    report: {
      parent: navListAclData.analysis.report,
      reports: reportAcl.parent,
      // "reports-leave-balances": reportAcl.leaves.balance,
      // "reports-schedules": reportAcl.schedules.allEmployee,
      // "reports-leave-applications-status":
      // 	reportAcl.leaves.applicationStatus,
      // "reports-daily-attendances": reportAcl.attendances.dailySummary,
      // "reports-employee-attendance": reportAcl.attendances.individualEmployee,
    },
  },
  hrm: {
    parent: navListAclData.hrm.parent,
    employees: {
      parent: navListAclData.hrm.employees,
      employees: navListAclData.hrm.employees,
      peers: defaultEmployeeAcl,
      teams: defaultEmployeeAcl,
      groups: defaultEmployeeAcl,
    },
    attendances: {
      parent: navListAclData.hrm.attendances,
      attendances: navListAclData.hrm.attendances,
    },
    leaves: {
      parent: navListAclData.hrm.leave,
      "employee-leave-applications": navListAclData.hrm.leave,
      "employee-leaves": navListAclData.hrm.leave,
    },
    schedules: {
      parent: navListAclData.hrm.schedules,
      schedules: navListAclData.hrm.schedules,
      // "fixed-hours": scheduleAcl.fixedHour.getAll,
    },
  },
  facilities: {
    parent: navListAclData.facilities.parent,
    assets: navListAclData.facilities.assets,
  },
  // for settings
  generalSettings: {
    parent: navListAclData.generalSettings.parent,
    "account-settings": navListAclData.generalSettings.accountSetting,
    systemSettings: {
      parent: navListAclData.generalSettings.systemSettings.parent,
      "global-settings": navListAclData.generalSettings.systemSettings.general,
      "employee-creation-settings": navListAclData.generalSettings.systemSettings.createAccountSettings,
    },
    notifications: navListAclData.generalSettings.notification,
  },
  hrmSettings: {
    parent: navListAclData.hrmSettings.parent,
    hrmManagement: {
      parent: navListAclData.hrmSettings.hrmManagement.parent,
      teams: navListAclData.hrmSettings.hrmManagement.team.getAll,
      groups: navListAclData.hrmSettings.hrmManagement.group.getAll,
      shifts: navListAclData.hrmSettings.hrmManagement.shift.getAll,
      "office-locations": navListAclData.hrmSettings.hrmManagement.locations.getAll,
      "financial-sessions": navListAclData.hrmSettings.hrmManagement.session.getAll,
    },
    leave: {
      parent: navListAclData.hrmSettings.leave.parent,
      "leave-settings": navListAclData.hrmSettings.leave.settings.getAll,
      "leave-types": navListAclData.hrmSettings.leave.leaveType.getAll,
    },
    approval: {
      parent: navListAclData.hrmSettings.approval.parent,
      "approval-policies": navListAclData.hrmSettings.leave.policy.getAll,
    },
    employment: {
      parent: navListAclData.hrmSettings.employment.parent,
      "employment-types": navListAclData.hrmSettings.employment.type.getAll,
      divisions: navListAclData.hrmSettings.employment.division.getAll,
      departments: navListAclData.hrmSettings.employment.department.getAll,
      grades: navListAclData.hrmSettings.employment.grade.getAll,
      designations: navListAclData.hrmSettings.employment.designation.getAll,
      "educational-qualification-settings": navListAclData.hrmSettings.employment.education.getAll,
    },
    acl: {
      parent: navListAclData.hrmSettings.acl.parent,
      users: navListAclData.hrmSettings.acl.user.getAll,
      roles: navListAclData.hrmSettings.acl.role.getAll,
    },
  },
  facilitySettings: {
    parent: navListAclData.facilitiesSettings.parent,
    bankInfo: {
      parent: navListAclData.facilitiesSettings.bankInfo.parent,
      banks: navListAclData.facilitiesSettings.bankInfo.bank.getAll,
      branches: navListAclData.facilitiesSettings.bankInfo.branch.getAll,
      "digital-bankings": navListAclData.facilitiesSettings.bankInfo.digitalBank.getAll,
    },
    facilities: {
      parent: navListAclData.facilitiesSettings.facilities.parent,
      holidays: navListAclData.facilitiesSettings.facilities.holidays.getAll,
      "asset-types": navListAclData.facilitiesSettings.facilities.assetType.getAll,
      "holiday-groups": navListAclData.facilitiesSettings.facilities.holidayGroup.getAll,
    },
  },
  integrations: {
    parent: navListAclData.integrationSettings.parent,
    attendanceDevice: {
      parent: navListAclData.integrationSettings.attendanceDevice.parent,
      devices: navListAclData.integrationSettings.attendanceDevice.attendanceDevices.getAll,
      "attendance-locations": navListAclData.integrationSettings.attendanceDevice.attendanceLocations.getAll,
    },
    apis: {
      parent: navListAclData.integrationSettings.apis.parent,
      "api-secret-keys": navListAclData.integrationSettings.apis.apiSecretKeys.getAll,
    },
  },
};
