import ability from "@acl/ability";
import { permissionMapping, processingMenuPermission } from "@acl/utils";
import { countryData } from "@components/Input/PhoneInput";
import { ISession } from "@interfaces/leave.interface";
import { IAccountPolicies } from "@pages/Settings/SystemSettings/SystemSettingInterface";
import { AuthService } from "@services/apis/Auth.service";
import { GlobalService } from "@services/apis/Global.service";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { ToastService } from "@services/utils/toastr.service";
import { topProgress } from "@services/utils/topProgress.service";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { cacheClear, formatDateToYYYYMMDD, isArray } from "utils";
import { isExpiredToken } from "utils/jwt";
import { systemSettingsMapper } from "utils/system.utils";

// TODO::need to handle proper type and inteface

type AuthProps = {
  isAuthenticated: boolean;
  isUnauthorizedOrd: boolean;
  authenticate: Function;
  makeAuthenticated: Function;
  logout: Function;
  setUser: Function;
  userData: any;
  setPermissions: Function;
  permissions: any[];
  systemSettings: any;
  setSystemSettings: Function;
  sessions: ISession[];
  setSessions: Function;
  isEmployee: boolean;
  currentSession: any;
  setCurrentSession: Function;
  accountPolicies: IAccountPolicies;
  setAccountPolicies: Function;
  isLoadingState: boolean;
  // setUnAuthorizedOrd: Function;
  hasOrg: boolean;
  setHasOrg: (value: boolean) => void;
};

export const AuthContext = React.createContext({} as AuthProps);

export const useAuth = () => useContext(AuthContext);

const isValidToken = () => {
  const accessToken = LocalStorageService.get("accessToken");

  if (!accessToken) return false;

  return !isExpiredToken(accessToken);
};

const AuthProvider = (props: any) => {
  // const navigate = useNavigate();

  const [isAuthenticated, makeAuthenticated] = useState<boolean>(isValidToken());
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isUnauthorizedOrd, setUnAuthorizedOrd] = useState<boolean>(false);

  const [userData, setUserData] = useState<any>({});
  const [permissions, setPermissionData] = useState<any>([]);

  const [systemSettings, setSystemSettings] = useState(LocalStorageService.get("systemSettings"));
  const [sessions, setSessions] = useState(LocalStorageService.get("sessions"));
  const [currentSession, setCurrentSession] = useState({});
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [accountPolicies, setAccountPolicies] = useState<IAccountPolicies>({} as IAccountPolicies);

  const localI18nextLng = LocalStorageService.get("local_i18nextLng");

  const [hasOrg, setHasOrg] = useState(false);

  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const baseUrl = () => {
    const hostname = window.location.hostname;
    const isMainDomain = hostname === import.meta.env.VITE_BASE_APP_URL;

    if (isMainDomain) {
      setHasOrg(false);
    } else {
      setHasOrg(true);
    }
  };

  useEffect(() => {
    baseUrl();
  }, [window.location.hostname]);

  // console.log({hasOrg});

  // const { i18n } = useTranslation();

  // const state = useSelector((data: any) => data.user);

  useEffect(() => {
    // dispatch(fetchCommonData());
    if (hasOrg) {
      getLoginInfo();
    }
  }, [isAuthenticated, hasOrg]);

  useEffect(() => {
    if (isArray(sessions)) {
      setCurrentSession(sessions?.find((item: any) => item?.is_current) || {});
    }
  }, [sessions]);

  // const { i18n } = useTranslation();

  // every 5 seconde checking the token
  useEffect(() => {
    // setInterval(() => {
    //   if (!isValidToken()) makeAuthenticated(isValidToken());
    //   getCommonData();
    // }, 5000);

    const handleStorage = (e: any) => {
      if (e.key === "accessToken" && e.oldValue && !e.newValue) {
        logout();
      }
      if (e.key === "systemSettings" && e.oldValue && e.newValue) {
        setSystemSettings(JSON.parse(e.newValue));
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    makeAuthenticated(isValidToken());
    getCommonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidToken()]);

  const authenticate = (accessToken: string, callBack: () => void) => {
    if (accessToken) {
      makeAuthenticated(true);
      LocalStorageService.set("accessToken", accessToken);
    }
    callBack();
  };

  const setUser = (user: any) => {
    LocalStorageService.set("userData", user);
    setUserData(user);
    setIsEmployee(user?.is_employee || false);
  };

  const setPermissions = (permissions: any[]) => {
    LocalStorageService.set("permissions", permissions);
    setPermissionData(permissions);
  };

  const getCommonData = () => {
    const data = LocalStorageService.get("userData");
    setUserData(data);
    setPermissionData(LocalStorageService.get("permissions"));
    setIsEmployee(data?.is_employee || false);
  };

  const getLoginInfo = async () => {
    setIsLoadingState(true);

    await cacheClear();

    await AuthService.loginInfo()
      .then(({ payload }) => {
        const { account_policies, financial_sessions, system_config } = payload;
        setSessions(financial_sessions);
        setAccountPolicies(account_policies);

        const countryLength = countryData.find(
          (country) => country.code === system_config.country_phone_code
        )?.maxLength;

        system_config.country_phone_code_max_length = countryLength || 10;

        const config = systemSettingsMapper(system_config);

        setSystemSettings(config);
        LocalStorageService.set("systemSettings", config);
        if (system_config?.language && !localI18nextLng) {
          i18n.changeLanguage(config?.language?.value);
        }
      })
      .catch((err) => {
        if (err.payload?.hasTenant === false) {
          setUnAuthorizedOrd(!err.payload?.hasTenant);
        }
      })
      .finally(() => {
        setIsLoadingState(false);
        topProgress.hide();
      });

    if (isAuthenticated) {
      AuthService.userInfo().then(async ({ payload }) => {
        // const userData = payload?.user_data;

        // const step = {};

        // Object.keys(userData.on_boarding_step)?.map((key, index) => {
        //   step[key] = {
        //     index: index,
        //     label: key,
        //     link: key,
        //     isActive: index == 0,
        //     isDone: userData?.on_boarding_step?.[key],
        //     [key]: userData?.on_boarding_step?.[key],
        //   };
        // });

        // payload.user_data.on_boarding_step = step;

        setUser(payload.user_data || []);

        if (payload?.permissions) {
          const mappingPermissions = await permissionMapping(processingMenuPermission(payload?.permissions));
          ability.update(mappingPermissions);
          setPermissions(mappingPermissions);
        }
      });
    }
  };

  const organizationData: any = LocalStorageService.get("dailyOrganization");
  const fetchAndStoreDailyOrganizationData = (user_id: string) => {
    GlobalService.activitiesUsers({ user_id, activate_at: formatDateToYYYYMMDD(new Date()) })
      .then((res: any) => {
        const response: any = res?.payload || [];
        LocalStorageService.set("dailyOrganization", {
          user_id: response?.user_id,
          activate_at: response.activate_at,
          is_exists: response.is_exists,
        });
      })
      .catch((err: any) => ToastService.error(err.message));
  };

  useEffect(() => {
    if (!organizationData && userData?.id) {
      fetchAndStoreDailyOrganizationData(userData.id);
    } else if (organizationData && organizationData?.activate_at !== formatDateToYYYYMMDD(new Date())) {
      if (!userData?.id) return;
      fetchAndStoreDailyOrganizationData(userData?.id);
    }
  }, [userData?.id]);

  const logout = () => {
    makeAuthenticated(false);
    LocalStorageService.clear();
    navigate("login");
  };

  const memoedProps = useMemo(
    () => ({
      authenticate,
      isAuthenticated,
      logout,
      setUser,
      userData,
      permissions,
      setPermissions,
      makeAuthenticated,
      systemSettings,
      setSystemSettings,
      sessions,
      setSessions,
      isEmployee,
      currentSession,
      setCurrentSession,
      accountPolicies,
      setAccountPolicies,
      isUnauthorizedOrd,
      isLoadingState,
      hasOrg,
      setHasOrg,
    }),
    [
      isAuthenticated,
      permissions,
      userData,
      sessions,
      currentSession,
      accountPolicies,
      systemSettings,
      isUnauthorizedOrd,
      isLoadingState,
      hasOrg,
      setHasOrg,
    ]
  );

  // context
  return (
    <AuthContext.Provider value={memoedProps}>
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
